<template>
  <div class="row mx-auto">
    <div
      class="card-item col-lg-6 col-12 col-md-6 mb-2 pe-1 ps-1"
      v-for="(value, index) in merchantVouchers"
      :item="value"
      :index="index"
      :key="index"
    >
      <div class="voucher-item custom-shadow">
        <div class="row">
          <div class="col-4">
            <img
              :src="`${value.icon_url}`"
              class="voucher-image"
              alt="user1"
              @click="rewardDetail(!isReward ? value.id : value.voucher_id, value.expiry_date_text)"
            />
          </div>
          <div class="col-8 ps-0">
            <div
              class="d-flex align-items-center"
              @click="rewardDetail(!isReward ? value.id : value.voucher_id, value.expiry_date_text)"
            >
              <span
                class="text-size-13 ps-0 w-70 text-bold"
                :style="`color: ${this.$store.state.labelColor} !important`"
                >{{ value.reward_title ?? "" }}</span
              >
              <div class="amount-exchange w-30 pe-1" v-if="!isReward">
                <span v-if="rewardPerTransaction == 1">
                  {{ value.amount_text }}
                </span>
                <span v-else-if="rewardPerTransaction == 2">
                  {{ value.point_text }}
                </span>
                <span v-else>$S 0</span>
              </div>
            </div>
            <div class="d-flex" v-if="isReward">
              <em class="text-size-12">Valid until: {{ value.expiry_date_text }}</em>
            </div>
            <div class="d-flex" v-if="!isReward">
              <em class="text-size-12">Valid for: {{ value.expiry_date_text }}</em>
            </div>
            <div v-if="!isReward">
              <button
                v-if="!value.is_disabled || !value.is_disabled_by_card"
                class="
                  mb-1
                  col-lg-5
                  no-bg
                  d-flex
                  justify-content-center
                  align-items-center
                  btn-sm btn-exchange
                "
                :style="`border: 1px solid ${this.$store.state.labelColor} !important; color:#222222`"
                data-bs-toggle="modal"
                data-bs-target="#bottom_modal"
                @click="exchange(value)"
              >
                Get
              </button>
            </div>
            <div
              v-if="isReward && value.locked"
              class="
                mb-1
                col-lg-5
                d-flex
                justify-content-center
                align-items-center
                btn-exchange
                px-3
                status-lock
              "
            >
              Locked
            </div>
            <div
              v-if="isReward && isExpiryMyReward"
              class="
                mb-1
                col-lg-5
                d-flex
                justify-content-center
                align-items-center
                btn-exchange
                px-3
              "
            >
              {{value.use == 0 ? 'Expired' : 'used' }} 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VoucherCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    isReward: {
      type: Boolean,
      default: false,
    },
    rewardPerTransaction: {
      type: Number,
      default: 0,
    },
    isExpiryMyReward: {
      type: Boolean,
      default: false,
    },
    merchantVouchers: {
      type: Array,
      default: () => [],
    },
    exchange: {
      type: Function,
      default: () => {},
    },
    rewardDetail: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<style scoped>
button:disabled {
  cursor: not-allowed;
  color: gray;
  background-color: rgb(229, 229, 229) !important;
  border: none !important;
}
.voucher-item {
  padding: 5px;
  background: hsla(0, 0%, 100%, 0.5);
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}
.voucher-image {
  border-radius: 10px;
}
.amount-exchange {
  color: #000000;
  font-weight: bold;
  width: auto;
  text-align: right;
}
.wrap-card .btn-exchange {
  position: absolute;
  right: 10px;
  bottom: 5px;
  min-width: 80px;
  max-width: 150px;
}
.status-lock {
  color: #fbc531 !important;
  border: 1px solid #fbc531 !important;
}
@media (min-width: 800px) {
  .voucher-image {
    width: 100%;
    height: 130px;
  }
}
@media (max-width: 500px) {
  .voucher-image {
    width: 100%;
    height: 100px;
  }
}
</style>
