
import MemberService from "../services/member.service";

const checkMemberPermissions = async (to, from, next) => {
  const { meta, name } = to;
  const isActive = meta && meta.active !== undefined ? meta.active : true;

  if (isActive) {
    try {
      const response = await MemberService.checkMemberPermissions();
      let listRouteCheck = [
        'Top-up',
        'Transactions',
        'My Rewards',
        'My Campaigns',
        'Reward Catelogue',
        'Invite Friends',
        'Find Branches',
        'Contact Us',
      ];
      let memberPermissions = response.data.data;

      if (listRouteCheck.includes(name)) {
        if (memberPermissions.includes(name)) {
          next(true);
        } else {
          next(false);
        }
      } else {
        next(true);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export default checkMemberPermissions;
