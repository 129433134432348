<template>
  <div class="page-header align-items-start min-vh-screen">
    <span class="mask bg-white"></span>
    <div class="container p-0">
      <div class="row">
        <div class="col-lg-12 col-md-8 col-12 mx-auto">
          <div class="card-body" style="padding: 0rem 0rem">
            <div>
              <div class="color-common pb-2 font-weight-bolder px-2 pt-2">
                <h3
                  class="ps-3"
                  :style="`color: ${this.$store.state.labelColor} !important`"
                >
                  My Rewards
                </h3>
              </div>
              <search-input
                :onChangesearchKeyword="onChangesearchKeyword"
                placeholder="Find your voucher"
              />
              <div class="row d-flex justify-content-center">
                <ul class="reward-tab" :style="styleObject">
                  <li
                    class="nav-item active"
                    id="nav_item_1"
                    @click="getMyRewards(1, searchKeyword, params)"
                    :style="`color: ${this.$store.state.labelColor} !important`"
                  >
                    <strong>New</strong>
                  </li>
                  <li
                    class="nav-item"
                    id="nav_item_2"
                    @click="getMyRewards(2, searchKeyword, params)"
                    :style="`color: ${this.$store.state.labelColor} !important`"
                  >
                    <strong>Used/Expired</strong>
                  </li>
                </ul>
              </div>
              <div v-if="myRewards.length > 0 && loadDataTabDone">
                <div class="wrap-card ps-1 pe-1">
                  <VoucherCard
                    :merchantVouchers="myRewards"
                    :rewardDetail="getRewardDetail"
                    :isReward="true"
                    :isExpiryMyReward="typeChecked == 2 ? true : false"
                  />
                </div>
              </div>
              <div v-if="myRewards.length == 0 && loadDataTabDone">
                <div
                  class="d-flex justify-content-center p-5"
                  style="background-color: #f0f2f5"
                >
                  No rewards available
                </div>
              </div>
            </div>
          </div>
        </div>
        <SortFilterToolbar
          :filterArrRewardType="filterArrRewardType"
          :filterArrStore="filterArrStore"
        />
      </div>
      <div class="position-fixed top-1 end-1 z-index-2">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
    </div>
    <Sort :filterData="filterData" :changeSortType="changeSortType" />
    <Filter
      :filterRewardType="filterRewardType"
      :filterArrRewardType="filterArrRewardType"
      :stores="stores"
      :filterStore="filterStore"
      :filterArrStore="filterArrStore"
      :filterData="filterData"
      :resetData="resetData"
    />
    <material-loading :active="loaderActive" />
    <QrcodeReward
      :rewardDetail="rewardDetail"
      :merchantMember="merchantMember"
      :isShowQr="typeChecked == 2 ? false : true"
    />
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput.vue";
import { mapMutations, mapActions, mapState } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import RewardService from "../services/reward.service";
import QrcodeReward from "@/components/QrcodeReward.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Sort from "@/components/Sort.vue";
import SortFilterToolbar from "@/components/SortFilterToolbar.vue";
import Filter from "@/components/Filter.vue";
import VoucherCard from "./components/VoucherCard.vue";
import "bootstrap/js/dist/modal";
import $ from "jquery";

export default {
  name: "my-transacions",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    QrcodeReward,
    SearchInput,
    Sort,
    Filter,
    SortFilterToolbar,
    VoucherCard,
  },
  data() {
    return {
      snackbar: null,
      loaderActive: false,
      merchantCode: "",
      myRewards: [],
      rewardsUsedAndExpired: [],
      typeChecked: 1,
      searchKeyword: "",
      searchTimerId: null,
      rewardDetail: {},
      merchantMember: {},
      stores: [],
      filterArrRewardType: [],
      filterArrStore: [],
      params: {},
      sortType: "1",
      loadDataTabDone: false,
    };
  },
  created() {
    this.getMyRewards(1, "", this.params);
    this.getBranches();
  },
  computed: {
    styleObject() {
      return {
        "--label-color": this.$store.state.labelColor,
      };
    },
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    ...mapMutations([
      "toggleEveryDisplay",
      "toggleHideConfig",
      "navbarMinimize",
    ]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
    ...mapState(["isPinned"]),

    closeSnackbar() {
      this.snackbar = null;
    },

    myRewardDetail(reward_id) {
      this.$router.push(this.merchantCode + "/my-reward-detail/" + reward_id);
    },

    getRewardDetail(rewardId, expiry_date_text = null) {
      this.showLoader();
      RewardService.getRewardDetail(rewardId, 1).then(
        (response) => {
          if (response.data.result.isSuccess) {
            let rewardData = response.data.data.reward;
            if (expiry_date_text) {
              rewardData.expiry_date_text = expiry_date_text
            }
            this.rewardDetail = rewardData;
            this.merchantMember = response.data.data.merchant_member;
            console.log(this.merchantMember);
            $(".btn-close").click();
            $(".btn-qrcode-modal").click();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getMyRewards(this.typeChecked, this.searchKeyword, this.params);
      }, 1000);
    },

    getMyRewards(type, search_keyword = "", params) {
      this.loadDataTabDone = false;
      this.myRewards = [];
      $(".nav-item").removeClass("active");
      $("#nav_item_" + type).addClass("active");
      search_keyword = this.searchKeyword;
      this.typeChecked = type;
      this.showLoader();
      RewardService.myRewards(type, search_keyword, params).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.myRewards = response.data.data;
            this.loadDataTabDone = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    changeSortType(event) {
      this.sortType = event.target.value;
    },

    filterRewardType(value) {
      const index = this.filterArrRewardType.indexOf(value);
      if (index > -1) {
        this.filterArrRewardType.splice(index, 1);
      } else {
        this.filterArrRewardType.push(value);
      }
    },

    filterStore(value) {
      const index = this.filterArrStore.indexOf(value);
      if (index > -1) {
        this.filterArrStore.splice(index, 1);
      } else {
        this.filterArrStore.push(value);
      }
    },

    getBranches() {
      this.stores = [];
      this.showLoader();
      RewardService.getBranches().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.stores = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    filterData() {
      $(".btn-close").click();
      $(".x-close").click();
      this.params = {
        sort_type: this.sortType,
        filter_reward_type: this.filterArrRewardType,
        filter_store: this.filterArrStore,
      };
      this.getMyRewards(this.typeChecked, this.searchKeyword, this.params);
    },
    resetData() {
      $(".btn-unclick").css({ "background-color": "#E3EBF7", color: "#000" });
      $(".btn-unclick2").css({ "background-color": "#E3EBF7", color: "#000" });
      this.filterArrRewardType = [];
      this.filterArrStore = [];
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style>
.btn-exchange {
  color: rgb(248, 37, 37);
  font-weight: 700;
  border: 1px solid rgb(248, 37, 37);
}

.reward-tab {
  margin: 0px !important;
  padding: 0px;
  width: 90% !important;
}

.reward-tab li {
  list-style: none;
  float: left;
  width: 50%;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.reward-tab li.active {
  border-bottom: 2px solid var(--label-color);
  color: var(--label-color);
}
@media only screen and (max-width: 600px) {
  .wrap-card .card-item:last-child {
    margin-bottom: 80px !important;
  }
}
</style>