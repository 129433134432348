<template>
  <div class="modal modal-bottom fade" id="bottom_modal_sort" tabindex="-1" role="dialog"
    aria-labelledby="bottom_modal_sort" style="z-index: 10000;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header row d-flex justify-content-center" :style="`background: ${this.$store.state.buttonColor} !important;`">
          <span class="x-close d-flex justify-content-left align-items-left" data-bs-dismiss="modal" aria-label="Close" :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`">X</span>
          <span class="modal-title" :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`">Sort</span>
        </div>
        <div class="modal-body">
          <div class="row">
            <MaterialRadio id="latest_added" name="sort_type" @change="changeSortType($event)" value="1" v-model="sort_type">
              Latest Added
            </MaterialRadio>
            <MaterialRadio id="earliest_added" name="sort_type" @change="changeSortType($event)" value="2" v-model="sort_type">
              Earliest Added
            </MaterialRadio>
            <MaterialRadio id="percentage_high_low" name="sort_type" @change="changeSortType($event)" value="3" v-model="sort_type">
              Percentage of high to low
            </MaterialRadio>
            <MaterialRadio id="percentage_low_high" name="sort_type" @change="changeSortType($event)" value="4" v-model="sort_type">
              Percentage of low to high
            </MaterialRadio>
            <MaterialRadio id="exchange_value_high_low" name="sort_type" @change="changeSortType($event)" value="5" v-model="sort_type">
              Exchange value high to low
            </MaterialRadio>
            <MaterialRadio id="exchange_value_low_high" name="sort_type" @change="changeSortType($event)" value="6" v-model="sort_type">
              Exchange value low to high
            </MaterialRadio>
          </div>
        </div>
        <div class="text-center">
          <material-button class="btn btn-sm mb-3 bg-color-common"
            :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`" @click="filterData()">
            Apply
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialRadio from "./MaterialRadio.vue";
export default {
  name: "Exchange",
  components: {
    MaterialRadio,
  },
  data() {
    return {
      sort_type: "1"
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    filterData: {
      type: Function,
      default: () => { },
    },
    changeSortType: {
      type: Function,
      default: () => { },
    },
  },
};

</script>
<style scoped>
.bg-color-common {
  color: #fff;
}

/* Css model */
.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog,
.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Left & Right */

.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 500px;
  max-width: 100%;
  height: 100%;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content {
  height: 100%;
  overflow: hidden;
}

.modal.modal-left .modal-body,
.modal.modal-right .modal-body {
  padding: 15px 15px 80px;
}

.modal.modal-left.fade .modal-dialog {
  left: -500px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.modal-left.fade.show .modal-dialog {
  left: 0;
}

.modal.modal-right.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.modal-right.fade.show .modal-dialog {
  right: 0;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content,
.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content,
.modal.modal-full .modal-content {
  border-radius: 0;
  border: none;
}

.modal.modal-left .modal-dialog.modal-sm,
.modal.modal-right .modal-dialog.modal-sm {
  width: 300px;
}

.modal.modal-left .modal-dialog.modal-lg,
.modal.modal-right .modal-dialog.modal-lg {
  width: 800px;
}

.modal.modal-left .modal-dialog.modal-xl,
.modal.modal-right .modal-dialog.modal-xl {
  width: 1140px;
}

/* Top and Bottom */

.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content {
  height: auto;
  overflow-y: auto;
}

.modal.modal-top .modal-body,
.modal.modal-bottom .modal-body {
  padding: 15px 15px;
}

/* Top */

.modal.modal-top.fade .modal-dialog {
  top: -100%;
  -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
  -o-transition: opacity 0.3s linear, top 0.3s ease-out;
  transition: opacity 0.3s linear, top 0.3s ease-out;
}

.modal.modal-top.fade.show .modal-dialog {
  top: 0;
}

/* Bottom */

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.modal-bottom.fade.show .modal-dialog {
  bottom: 0;
}

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
}

/* Full Screen */

.modal.modal-full .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.modal-full .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-full .close-modal {
  position: fixed;
  top: 0;
  right: 3rem;
}

/* Footer */

.modal-footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0;
}

/* XS */

.modal.modal-left.xs .modal-body,
.modal.modal-right.xs .modal-body {
  padding: 15px;
}

.btn-close {
  font-size: 25px;
  color: #222 !important;
}

.btn-close span {
  color: #222;
}
.form-check:not(.form-switch) .form-check-input[type=radio]:after {
  left: -1px !important;
}
.btn-close{
    font-size: 15px;
}
.x-close {
  width: auto;
  color: #222222;
  position: absolute;
  left: 0px;
  font-weight: bold;
}
.modal-title, .x-reset {
  width: auto;
  font-weight: bold;
  color: #222222;
}
.modal-header {
  padding-top: 5px;
  padding-bottom: 5px;
}
.x-reset {
  position: absolute;
  right: 0px;
  color: #222222;
   font-weight: bold;
}
.x-close:hover, .x-reset:hover {
  cursor: pointer;
}
</style>
