<template>
    <div class="page-header align-items-start min-vh-100 my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
            <div class="row">
                <div class="notifications">
                    <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
                        {{ error }}
                    </material-alert>
                </div>
                <div class="col-lg-12 col-md-8 col-12 mx-auto">
                    <div class="card-body">
                        <div>
                            <div class="color-common pb-3 font-weight-bolder h4"
                                :style="`color: ${this.$store.state.headerColor} !important`">
                                NOTIFICATION
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2">
            <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                :close-handler="closeSnackbar" />
        </div>
    </div>

</template>

<script>
import MaterialAlert from "@/components/MaterialAlert.vue";
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MemberService from "../services/member.service";
import 'bootstrap/dist/js/bootstrap.bundle'

export default {
    name: "my-transacions",
    components: {
        MaterialAlert,
        MaterialSnackbar,
    },
    data() {
        return {
            listTransaction: [],
            membershipCashback: 0,
            currentTime: ''
        };
    },
    created() {
        this.getMyTransactions()
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),

        getMyTransactions() {
            MemberService.getMyTransactions().then(
                (response) => {
                    this.listTransaction = response.data.data.list_transaction
                    this.membershipCashback = response.data.data.membership_cashback ? response.data.data.membership_cashback : 0
                    this.currentTime = response.data.data.current_year_month
                },
                (error) => {
                    console.log(error);
                }
            );
        },

        transactionDetails(transaction_id) {
            this.$router.push('/transaction-detail/' + transaction_id);
        }
    },
};
</script>
<style>
.my-transacions .accordion div button[aria-expanded=false] .collapse-close {
    display: block;
}

.my-transacions .accordion div button[aria-expanded=true] .collapse-close {
    display: none;
}

.text-custom {
    color: black !important;
    font-weight: 500;
}

.group-month {
    font-weight: 400;
}

.group-month {
    margin-right: 10px;
}

.my-transacions [aria-expanded="true"] i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}

.brand-name {
    cursor: pointer
}
</style>