<template>
  <div class="modal modal-bottom fade" id="bottom_modal_filter" tabindex="-1" role="dialog"
    aria-labelledby="bottom_modal_filter" style="z-index: 10000;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header row d-flex justify-content-center"
          :style="`background: ${this.$store.state.buttonColor} !important;`">
          <span class="x-close d-flex justify-content-left align-items-left" data-bs-dismiss="modal" aria-label="Close"
            :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`">X</span>
          <span class="modal-title"
            :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`">Filter</span>
          <span class="x-reset" @click="resetData()"
            :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`">Reset</span>
        </div>
        <div class="modal-body">
          <div class="pb-2" :style="`color: ${this.$store.state.labelColor} !important`">
            Status
          </div>
          <div>
            <div :class="`${isStatusAll ? 'btn-active' : 'btn-deactive'} badge badge-sm me-2 mb-2`"
              @click="checkAllStatus(true),  filterCashbackType(idStatus)">All
            </div>
            <div v-for="(value, index) in statusText" :key="index"
              :class="`${idStatus.includes(index) ? 'btn-active' : 'btn-deactive'} badge badge-sm me-2 mb-2`"
              @click="checkStatus(index), filterCashbackType(idStatus)">{{ value }}
            </div>
          </div>
          <div class="pb-2" :style="`color: ${this.$store.state.labelColor} !important`">
            Stores
          </div>
          <div>
            <div v-for="(value, index) in stores" :key="index" class="badge badge-sm me-2 mb-2 btn-unclick2"
              @click="filterStore(value.id), changeStore()" :id="`store_${value.id}`">{{ value.branch_name }}</div>
          </div>
          <div class="pb-2" :style="`color: ${this.$store.state.labelColor} !important`">
            Months
          </div>
          <div>
            <div class="badge badge-sm me-2 mb-2 btn-unclick3" @click="filterCashbackTime(0), changeCashbackTime(0)"
              id="cashback_time_0">All</div>
            <div class="badge badge-sm me-2 mb-2 btn-unclick3" @click="filterCashbackTime(1), changeCashbackTime(1)"
              id="cashback_time_1">1 day</div>
            <div class="badge badge-sm me-2 mb-2 btn-unclick3" @click="filterCashbackTime(2), changeCashbackTime(2)"
              id="cashback_time_2">1 week</div>
            <div class="badge badge-sm me-2 mb-2 btn-unclick3" @click="filterCashbackTime(3), changeCashbackTime(3)"
              id="cashback_time_3">1 month</div>
            <div class="badge badge-sm me-2 mb-2 btn-unclick3" @click="filterCashbackTime(4), changeCashbackTime(4)"
              id="cashback_time_4">3 month</div>
            <div class="badge badge-sm me-2 mb-2 btn-unclick3" @click="filterCashbackTime(5), changeCashbackTime(5)"
              id="cashback_time_5">1 year</div>
          </div>
        </div>
        <div class="text-center">
          <material-button class="btn btn-sm mb-3 bg-color-common"
            :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
            @click="filterData()">
            Apply
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "./MaterialButton.vue";
import RewardService from "../services/reward.service";
import $ from "jquery"
export default {
  name: "Exchange",
  components: {
    MaterialButton,
  },
  data() {
    return {
      stores: [],
      idStatus: [],
      statusText: ['Pending', 'Confirmed'],
      isStatusAll: false,
      loaderActive: false,
      isLoadDone: false,
    };
  },
  created() {
    this.getBranches();
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    filterCashbackType: {
      type: Function,
      default: () => { },
    },
    filterStore: {
      type: Function,
      default: () => { },
    },
    filterArrStore: {
      type: Array
    },
    filterCashbackTime: {
      type: Function,
      default: () => { },
    },
    filterData: {
      type: Function,
      default: () => { },
    },
    resetData: {
      type: Function,
      default: () => { },
    },
  },
  methods: {
    getBranches() {
      this.stores = [];
      this.showLoader();
      RewardService.getBranches().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.stores = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    checkStatus(statusId = "") {
      if (statusId >= 0) {
        if (this.idStatus.includes(statusId)) {
          this.deleteStatus(statusId);
        } else {
          this.idStatus.push(statusId);
        }
      }
      this.checkAllStatus();
    },
    deleteStatus(statusId = "") {
      this.idStatus.forEach((value, index) => {
        if (value == statusId) {
          this.idStatus.splice(index, 1);
        }
      });
    },

    checkAllStatus(isAll = false) {
      if (isAll) {
        this.isStatusAll = !this.isStatusAll;
        if (this.isStatusAll) {
          this.idStatus = [0, 1];
        } else {
          this.idStatus = [];
        }
      } else {
        if (this.idStatus.length == 2) {
          this.isStatusAll = true;
        } else {
          this.isStatusAll = false;
        }
      }
    },

    changeStore() {
      $(".btn-unclick2").css({ 'background-color': '#E3EBF7', 'color': '#000' })
      this.filterArrStore.forEach((item) => {
        $("#store_" + item).css({ 'background-color': '#14A44D', 'color': '#fff' })
      })
    },

    changeCashbackTime(value) {
      $(".btn-unclick3").css({ 'background-color': '#E3EBF7', 'color': '#000' })
      $("#cashback_time_" + value).css({ 'background-color': '#14A44D', 'color': '#fff' })
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};

</script>
<style scoped>
.btn-active {
  background-color: #14A44D;
  color: #fff;
}

/* Css model */
.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog,
.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Left & Right */

.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 500px;
  max-width: 100%;
  height: 100%;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-left .modal-body,
.modal.modal-right .modal-body {
  padding: 15px 15px 80px;
}

.modal.modal-left.fade .modal-dialog {
  left: -500px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.modal-left.fade.show .modal-dialog {
  left: 0;
}

.modal.modal-right.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.modal-right.fade.show .modal-dialog {
  right: 0;
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content,
.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content,
.modal.modal-full .modal-content {
  border-radius: 0;
  border: none;
}

.modal.modal-left .modal-dialog.modal-sm,
.modal.modal-right .modal-dialog.modal-sm {
  width: 300px;
}

.modal.modal-left .modal-dialog.modal-lg,
.modal.modal-right .modal-dialog.modal-lg {
  width: 800px;
}

.modal.modal-left .modal-dialog.modal-xl,
.modal.modal-right .modal-dialog.modal-xl {
  width: 1140px;
}

/* Top and Bottom */

.modal.modal-top .modal-dialog,
.modal.modal-bottom .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.modal.modal-top .modal-content,
.modal.modal-bottom .modal-content {
  height: auto;
  overflow: hidden;
}

.modal.modal-top .modal-body,
.modal.modal-bottom .modal-body {
  padding: 15px 15px;
}

/* Top */

.modal.modal-top.fade .modal-dialog {
  top: -100%;
  -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
  -o-transition: opacity 0.3s linear, top 0.3s ease-out;
  transition: opacity 0.3s linear, top 0.3s ease-out;
}

.modal.modal-top.fade.show .modal-dialog {
  top: 0;
}

/* Bottom */

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.modal-bottom.fade.show .modal-dialog {
  bottom: 0;
}

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
}

/* Full Screen */

.modal.modal-full .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.modal-full .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.modal-full .close-modal {
  position: fixed;
  top: 0;
  right: 3rem;
}

/* Footer */

.modal-footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0;
}

/* XS */

.modal.modal-left.xs .modal-body,
.modal.modal-right.xs .modal-body {
  padding: 15px;
}

.btn-close {
  font-size: 25px;
  color: #222 !important;
}

.btn-close span {
  color: #222;
}

.btn-deactive {
  background: #E3EBF7;
  color: #000;
}

.btn-unclick2 {
  background: #E3EBF7;
  color: #000;
}

.btn-unclick3 {
  background: #E3EBF7;
  color: #000;
}

.btn-clicked {
  background-color: #14A44D !important;
  color: #fff !important;
}

.badge {
  text-transform: capitalize;
}

.btn-close {
  font-size: 15px;
}

.x-close {
  width: auto;
  color: #222222;
  position: absolute;
  left: 0px;
  font-weight: bold;
}

.modal-title,
.x-reset {
  width: auto;
  font-weight: bold;
  color: #222222;
}

.modal-header {
  padding-top: 5px;
  padding-bottom: 5px;
}

.x-reset {
  position: absolute;
  right: 0px;
  color: #222222;
  font-weight: bold;
}

.x-close:hover,
.x-reset:hover {
  cursor: pointer;
}
</style>
