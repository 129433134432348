import api from './api';

class RewardService {
  getList(params) {
    return api.post('/member/rewards', params);
  }

  getRewardDetail(reward_id, is_myreward = 0, campaign_id = 0) {
    return api.get('/member/rewards/detail/' + reward_id + '/' + is_myreward + '/' + campaign_id);
  }

  processExchange(reward_id) {
    return api.post('/member/rewards/exchange', { reward_id: reward_id });
  }

  myRewards(type, search_keyword, params) {
    params.type = type
    params.search_keyword = search_keyword
    return api.post('/member/rewards/my-reward', params);
  }

  getBranches() {
    return api.post('/member/branches');
  }
}

export default new RewardService();
