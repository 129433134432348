<template>
  <div class="page-header align-items-start min-vh-100">
    <span class="mask bg-white"></span>
    <div class="container" v-if="isShowLayout">
      <div class="row">
        <div class="notifications">
          <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
            {{ error }}
          </material-alert>
        </div>
        <div class="col-lg-12 col-md-12 col-12 pt-2 custom-icon-back py-2">
          <a @click="myRewards()"><i class="fas fa-chevron-left color-common"></i></a>
        </div>
        <div class="col-lg-12 col-md-8 col-12 mx-auto">
          <div class="row ">
            <div class="color-common font-weight-bolder reward-title" :style="`color: ${this.$store.state.labelColor} !important`">
              <strong>{{ rewardDetail.reward_title }}</strong>
            </div>
          </div>
          <div class="row mb-2">
            <div class="color-common reward-title">
              <strong>Expires: {{ rewardDetail.end_time }}</strong>
            </div>
          </div>
          <div class="row mb-3">
            <vue-barcode :value="rewardDetail.code" :options="{ displayValue: true }"></vue-barcode>
          </div>
          <div class="row mb-4 mx-1">
            <div class="color-common font-weight-bolder">
              Terms & Conditions
            </div>
          </div>
          <div v-for="(value, index) in rewardDetail.terms_arr" :item="value" :index="index" :key="index"
            class="row mb-4 mx-1">
            <div class="d-flex bd-highlight">
              <i class="fas fa-check pe-2 pt-1"></i>
              <p class="font-content text-dark">{{ value }}</p>
            </div>
          </div>

          <div class="row mb-4 mx-1">
            <div class="color-common font-weight-bolder">
              Where to use:
            </div>
          </div>
          <div class="row mb-4 mx-1">
            <div class="d-flex bd-highlight">
              <i class="fas fa-check pe-2 pt-1"></i>
              <p class="font-content text-dark where-to-use" v-html="rewardDetail.where_to_use"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date="" :description="message"
        :icon="{ component: 'campaign', color: 'white' }" :color="snackbar" :close-handler="closeSnackbar" />
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>

<script>
import MaterialAlert from "@/components/MaterialAlert.vue";
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import RewardService from "../services/reward.service";
import 'bootstrap/dist/js/bootstrap.bundle'
import MaterialLoading from "@/components/MaterialLoading.vue";
import VueBarcode from '@chenfengyuan/vue-barcode';

export default {
  name: "reward-detail",
  components: {
    MaterialAlert,
    MaterialSnackbar,
    MaterialLoading,
    VueBarcode
  },
  data() {
    return {
      rewardDetail: {},
      merchantMember: {},
      loaderActive: false,
      snackbar: null,
      isShowLayout: false,
    };
  },
  created() {
    this.getRewardDetail()
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),

    getRewardDetail() {
      this.showLoader()
      var rewardId = this.$route.params.reward_id
      RewardService.getRewardDetail(rewardId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.rewardDetail = response.data.data.reward
            this.merchantMember = response.data.data.merchant_member
            this.isShowLayout = true
          } else {
            this.snackbar = 'danger'
            this.message = response.data.result.message
          }
          this.hideLoader()
        },
        (error) => {
          this.snackbar = 'danger'
          this.message = error
          this.hideLoader()
        }
      )
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => { this.snackbar = null; }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    myRewards() {
      this.$router.go(-1)
    }
  },
};
</script>
<style>
.where-to-use a {
  color: #007BFF !important;
  text-decoration: underline !important;
}

.custom-icon-back {
  font-size: 25px;
}

.font-content {
  font-size: 13px;
  font-weight: 600;
}

.reward-title{
  font-size: 14px;
}
</style>