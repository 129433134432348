<template>
    <div :style="styleObject" class="page-header align-items-start min-vh-100">
        <span class="mask bg-white"></span>
        <div class="container">
            <div class="row">
                <div class="notifications">
                    <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
                        {{ error }}
                    </material-alert>
                </div>
                <div class="col-lg-12 col-md-8 col-12 mx-auto">
                    <div class="card-body">
                        <div>
                            <!-- <div class="color-common pb-3 font-weight-bolder h4"
                                :style="`color: ${this.$store.state.labelColor} !important`">
                                My Minty Profile
                            </div> -->
                            <div class="pb-3 font-weight-bolder text-dark title-share-profile" v-if="!isShowShareData">
                                Share personal info from MINTY? <a class="text-decoration-underline btn-share-profile"
                                    data-bs-toggle="modal" data-bs-target="#bottom_modal_share_profile">Share now</a><i
                                    class="fa fa-times ps-2" aria-hidden="true"></i>
                            </div>
                            <div class="input-group input-group-static mb-3">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Member ID
                                    <br>
                                    <div class="text-left" v-html="form.id" :style="`color: ${this.$store.state.labelColor} !important`"></div>
                                </label>
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isNameDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Name</label>
                                    <span v-if="required && required.isNameRequire" class="text-danger ms-2">*</span>
                                    <i v-if="reward && reward.isNameReward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                <div class="form-control p-0" id="form-control">
                                    <material-input id="name" name="name" v-model="form.name" />
                                </div>
                                <!-- <span class="is-invalid" v-if="required && required.isNameRequire">The field is required.</span> -->
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isDateDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Date of
                                    Birth</label>
                                    <span v-if="required && required.isDateRequire" class="text-danger ms-2">*</span>
                                    <i v-if="reward && reward.isDateReward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                <div class="form-control p-0" v-bind:class="form.date_of_birth ? 'no-bg' : ''"
                                    id="form-control">
                                    <material-input v-if="!dateOfBirthBefore && !check_birthday_reward" id="date_of_birth" type="date"
                                        name="date_of_birth" v-model="form.date_of_birth" />
                                    <div v-if="dateOfBirthBefore" @click="warningCannotUpdateBirthday()">{{ form.date_of_birth }}</div>
                                    <span class="is-invalid" v-if="dateOfBirthBefore && isWarningCannotUpdateBirthday">Birthday can only be changed once, before any gift redemption.</span>
                                </div>
                                <span style="font-weight: bold;">Note: <span style="font-weight: 300;font-style:italic;">The day and month of your birthday (ddmm) will be used as your 4-digit default passcode, please enter correctly. Birthday can only be changed once, before any gift redemption.</span></span>
                            </div>
                            <div class="input-group input-group-static ml-2 mb-3 gender-group" v-if="displayFields && displayFields.isGenderDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Gender</label>
                                <span v-if="required && required.isGenderRequire" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isGenderReward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                <div class="form-control p-0 d-flex pb-2 no-bg" id="form-control">
                                    <MaterialRadio id="make" name="gender" value="1" extraClass="pl-0"
                                        v-model="form.gender">
                                        Male
                                    </MaterialRadio>
                                    <MaterialRadio id="female" name="gender" value="2" v-model="form.gender">
                                        Female
                                    </MaterialRadio>
                                </div>
                                <!-- <span class="is-invalid" v-if="required && required.isGenderRequire">The field is required.</span> -->
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isEmailDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Email
                                    Address</label>
                                <span v-if="required && required.isEmailRequire" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isEmailReward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                <div class="form-control p-0" id="form-control">
                                    <material-input id="email" type="email" name="email" v-model="form.email" />
                                </div>
                                <!-- <span class="is-invalid" v-if="required && required.isEmailRequire">The field is required.</span> -->
                            </div>
                            <div class="input-group input-group-static mb-3">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Mobile
                                    Number</label>
                                <span class="text-danger ms-2">*</span>
                                <div class="form-control p-0 d-flex no-bg" id="form-control">
                                    <span class="pt-2 me-3  boder-b">+65</span>
                                    <material-input id="phone_number" type="tel" name="phone_number" class="me-2"
                                        :disabled="isDisablePhoneInput" v-model="form.phone_number"
                                        @input="onChangePhoneNumber" />
                                    <material-button class="mb-1 col-lg-2 col-md-3 col-4 bg-green"
                                        :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`"
                                        @click="sendOTP()" :disabled="isDisableGetOtpBtn">
                                        <div class="text-center" v-html="countDownText"></div>
                                    </material-button>
                                    <button type="button" style="display: none;" class="open-modal" name="open_modal"
                                        data-bs-toggle="modal" data-bs-target="#exampleModal"></button>
                                </div>
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isHouseAddressDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Address</label>
                                <span v-if="required && required.isHouseAddressRequire" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isHouseAddressReward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                <div class="form-control p-0 mt-1" id="form-control">
                                    <material-input id="address_1" name="address_1" v-model="form.address_1" />
                                </div>
                                <!-- <span class="is-invalid" v-if="required && required.isHouseAddressRequire">The field is required.</span> -->
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isAddress2Display">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Address 2</label>
                                <span v-if="required && required.isAddress2Require" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isAddress2Reward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                <div class="form-control p-0 mt-1" id="form-control">
                                    <material-input id="address_2" name="address_2" v-model="form.address_2" />
                                </div>
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isPostCodeDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Postal
                                    Code</label>
                                <span v-if="required && required.isPostCodeRequire" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isPostCodeReward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                <div class="form-control p-0 mt-1" id="form-control">
                                    <material-input id="postal_code" name="postal_code" v-model="form.postal_code" />
                                </div>
                                <!-- <span class="is-invalid" v-if="required && required.isPostCodeRequire">The field is required.</span> -->
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isOccupationDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Occupation</label>
                                <span v-if="required && required.isOccupationRequire" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isOccupationReward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                <div class="form-control p-0 mt-1" id="form-control">
                                    <material-input id="occupation" name="occupation" v-model="form.occupation" />
                                </div>
                                <!-- <span class="is-invalid" v-if="required && required.isOccupationRequire">The field is required.</span> -->
                            </div>
                            <div class="input-group input-group-static mb-3 monthly-group" v-if="displayFields && displayFields.isMonthlyIncomeRangeDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Monthly Income
                                    Range</label>
                                <span v-if="required && required.isMonthlyIncomeRangeRequire" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isMonthlyIncomeRangeReward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                <div class="form-control p-0 mt-1 pb-2 no-bg" id="form-control">
                                    <div class="row">
                                        <MaterialRadio id="range_1" name="monthly_income_range" value="1"
                                            v-model="form.monthly_income_range">
                                            $0 - $3,000
                                        </MaterialRadio>
                                    </div>
                                    <div class="row">
                                        <MaterialRadio id="range_2" name="monthly_income_range" value="2"
                                            v-model="form.monthly_income_range">
                                            $3,001 - $6,000
                                        </MaterialRadio>
                                    </div>
                                    <div class="row">
                                        <MaterialRadio id="range_3" name="monthly_income_range" value="3"
                                            v-model="form.monthly_income_range">
                                            $6,001 - $9,000
                                        </MaterialRadio>
                                    </div>
                                    <div class="row">
                                        <MaterialRadio id="range_4" name="monthly_income_range" value="4"
                                            v-model="form.monthly_income_range">
                                            Above $9,000
                                        </MaterialRadio>
                                    </div>
                                </div>
                                <!-- <span class="is-invalid" v-if="required && required.isMonthlyIncomeRangeRequire">The field is required.</span> -->
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isMaritalStatusDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Marital
                                    Status</label>
                                <span v-if="required && required.isMaritalStatusRequire" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isMaritalStatusReward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                <div class="form-control p-0 mt-1 pb-2 no-bg" id="form-control">
                                    <select id="marital_status" class="form-select p-2" v-model="form.marital_status">
                                        <option value="">Select Marital Status</option>
                                        <option value="1">Married</option>
                                        <option value="2">Single</option>
                                    </select>
                                </div>
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isDiscoveryDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Discovery
                                    Channel</label>
                                <span v-if="required && required.isDiscoveryRequire" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isDiscoveryReward" class="fas fa-gift icon-reward"></i>
                                <div class="form-control p-0 mt-1 pb-2 no-bg" id="form-control">
                                    <select id="discovery_channel" class="form-select p-2"
                                        v-model="form.discovery_channel">
                                        <option value="">Select Discovery Channel</option>
                                        <option value="1">Social Media</option>
                                        <option value="2">Traditional Media</option>
                                        <option value="3">Word of Mouth</option>
                                        <option value="4">Events</option>
                                        <option value="5">Others</option>
                                    </select>
                                </div>
                                <!-- <span class="is-invalid" v-if="required && required.isDiscoveryRequire">The field is required.</span> -->
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isCountryOfResidentDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Country Of
                                    Resident</label>
                                <span v-if="required && required.isCountryOfResidentRequire" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isCountryOfResidentReward" class="fas fa-gift icon-reward"></i>
                                <div class="form-control p-0 mt-1 pb-2 no-bg" id="form-control">
                                    <select id="country_id" class="form-select p-2" v-model="form.country_id">
                                        <option value="">Select Country Of Resident</option>
                                        <option v-for="(value, key) in countries" :key="key" :value="value.id">{{
                                                value.country_name
                                        }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isEducationDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Education</label>
                                <span v-if="required && required.isEducationRequire" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isEducationReward" class="fas fa-gift icon-reward"></i>
                                <div class="form-control p-0 mt-1 pb-2 no-bg" id="form-control">
                                    <select id="education" class="form-select p-2" v-model="form.education">
                                        <option value="">Select Education</option>
                                        <option value="1">Secondary</option>
                                        <option value="2">Tertiary Education</option>
                                        <option value="3">Bachelor</option>
                                        <option value="4">Postgraduate</option>
                                    </select>
                                </div>
                            </div>
                            <div v-for="(value, key) in form.fields_another" :key="key">
                                <div class="input-group input-group-static mb-3" v-if="value.is_short_anwser == 1 && value.is_display == 1">
                                    <label for="form-control" class="ms-0"
                                        :style="`color: black !important`">{{ value.field_name }}</label>
                                    <span v-if="value && value.is_required" class="text-danger ms-2">*</span>
                                    <i v-if="value && value.is_reward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                    <div class="form-control p-0 mt-1" id="form-control">
                                        <material-input :id="value.field_name_convert" :name="value.field_name_convert" v-model="value.field_value" />
                                    </div>
                                </div>
                                <div class="input-group input-group-static mb-3" v-if="value.is_paragraph == 1  && value.is_display == 1">
                                    <label for="form-control" class="ms-0"
                                        :style="`color: black !important`">{{ value.field_name }}</label>
                                    <span v-if="value && value.is_required" class="text-danger ms-2">*</span>
                                    <i v-if="value && value.is_reward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                    <div class="form-control p-0 mt-1" id="form-control">
                                        <material-textarea :id="value.field_name_convert" :name="value.field_name_convert" v-model="value.field_value" />
                                    </div>
                                </div>
                                <div class="input-group input-group-static mb-3" v-if="value.is_date == 1  && value.is_display == 1">
                                    <label for="form-control" class="ms-0"
                                        :style="`color: black !important`">{{ value.field_name }}</label>
                                    <span v-if="value && value.is_required" class="text-danger ms-2">*</span>
                                    <i v-if="value && value.is_reward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                    <div class="form-control p-0 mt-1" id="form-control">
                                        <Datepicker
                                            v-model="value.field_value"
                                            :enableTimePicker="false"
                                            class="date-time"
                                        />
                                    </div>
                                </div>
                                <div class="input-group input-group-static mb-3" v-if="value.is_time == 1  && value.is_display == 1">
                                    <label for="form-control" class="ms-0"
                                        :style="`color: black !important`">{{ value.field_name }}</label>
                                    <span v-if="value && value.is_required" class="text-danger ms-2">*</span>
                                    <i v-if="value && value.is_reward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                    <div class="form-control p-0 mt-1" id="form-control">
                                        <Datepicker
                                            v-model="value.field_value"
                                            timePicker
                                            class="date-time"
                                        />
                                    </div>
                                </div>
                                <div class="input-group input-group-static mb-3" v-if="value.is_single_choice == 1  && value.is_display == 1">
                                    <label for="form-control" class="ms-0"
                                        :style="`color: black !important`">{{ value.field_name }}</label>
                                    <span v-if="value && value.is_required" class="text-danger ms-2">*</span>
                                    <i v-if="value && value.is_reward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                    <div class="form-control">
                                        <div v-for="(option, index) in value.options" :key="index">
                                            <MaterialRadio
                                                style="padding-left: 0px;"
                                                :id="option.id"
                                                :value="option.id"
                                                v-model="value.field_value"
                                            >
                                                {{ option.name }}
                                            </MaterialRadio>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group input-group-static mb-3" v-if="value.is_multiple_choice == 1  && value.is_display == 1">
                                    <label for="form-control" class="ms-0"
                                        :style="`color: black !important`">{{ value.field_name }}</label>
                                    <span v-if="value && value.is_required" class="text-danger ms-2">*</span>
                                    <i v-if="value && value.is_reward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                    <div class="form-control">
                                        <div v-for="(option, index) in value.options" :key="index">
                                            <div style="display: inline-flex;gap: 10px;">
                                                <div class="d-flex align-items-center">
                                                    <material-checkbox
                                                        @change="addMultipleChoice(value, option)"
                                                        :checked="option.checked === option.id"
                                                        :name="value.field_name_convert"
                                                        :id="value.field_name_convert"
                                                        class="checkbox-custom"
                                                        
                                                    ></material-checkbox>
                                                </div>
                                                <div
                                                    class="label-form d-flex align-items-center"
                                                >
                                                    {{ option.name }}
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group input-group-static mb-3" v-if="value.is_file_upload == 1  && value.is_display == 1">
                                    <label for="form-control" class="ms-0"
                                        :style="`color: black !important`">{{ value.field_name }}</label>
                                    <span v-if="value && value.is_required" class="text-danger ms-2">*</span>
                                    <i v-if="value && value.is_reward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                    <div class="form-control">
                                        <div>
                                            <material-input
                                                style="display: none"
                                                type="file"
                                                :id="value.field_name_convert"
                                                @click="uploadFile(value)"
                                                @change="handleFileUpload($event, value)"
                                                accept="image/*"
                                            />
                                            <material-button
                                                @click="uploadFile(value)"
                                                class="mx-2 btn-sm btn-background"
                                            >
                                                UPLOAD FILE
                                            </material-button>
                                            <div class="pt-2 text-size-12">
                                                Upload image maximum 2M
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-4 col-12">
                                            <div id="preview" style="position: relative">
                                                <img
                                                    style="width: 26%;"
                                                    v-if="value.field_value_preview || value.field_value"
                                                    :src="value.field_value_preview || value.field_value"
                                                />
                                                <i
                                                    class="
                                                        fa fa-times
                                                        icon-close-sidenav-main
                                                        danger
                                                    "
                                                        v-if="value.field_value_preview || value.field_value" 
                                                        style="
                                                        position: absolute;
                                                        top: 0px;
                                                        left: 100px;
                                                        color: #f44335;
                                                        font-size: 20px;
                                                        "
                                                        aria-hidden="true"
                                                        @click="clearImage(value)"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="input-group input-group-static mb-3" v-if="displayFields && displayFields.isPasscodeDisplay">
                                <label for="form-control" class="ms-0"
                                    :style="`color: black !important`">Passcode
                                </label>
                                <span v-if="required && required.isPasscodeRequire" class="text-danger ms-2">*</span>
                                <i v-if="reward && reward.isPasscodeReward" class="fas fa-gift icon-reward" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Fill in this field to get reward!" data-bs-original-title="Fill in this field to get reward!"></i>
                                <div class="form-control p-0 mt-1 inputs d-flex flex-row justify-content-center  pb-2 no-bg"
                                    id="form-control">
                                    <v-otp-input input-classes="otp-input" separator="" :num-inputs="4"
                                        ref="inputPassword" input-type="password" :is-input-num="true"
                                        :conditionalClass="['one', 'two', 'three', 'four']" v-model="form.password"
                                        @on-complete="handleOnCompletePassword" @on-change="handleOnChangePassword" />
                                </div>
                            </div>
                            <div class="text-center">
                                <material-button @click="updateProfile()" class="mb-3 col-lg-4 col-md-5 col-6 bg-green"
                                    :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`">
                                    Save
                                </material-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2">
            <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                :close-handler="closeSnackbar" />
        </div>
        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            ref="vuemodal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 :style="`color: ${this.$store.state.labelColor} !important`" class="modal-title font-weight-normal" id="exampleModalLabel">Please enter OTP code</h5>
                        <button type="button"
                            class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="input-group input-group-static mb-3">
                            <label for="form-control" class="ms-0">4-Digit OTP Code</label>
                            <div class="form-control p-0 mt-1 inputs d-flex flex-row justify-content-center  pb-2 no-bg"
                                id="form-control">
                                <v-otp-input ref="inputOTP" input-classes="otp-input" separator="-" :num-inputs="4"
                                    :is-input-num="true" :conditionalClass="['one', 'two', 'three', 'four']"
                                    :placeholder="['*', '*', '*', '*']" v-model="form.otp"
                                    @on-complete="handleOnComplete" @on-change="handleOnChange" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="text-center" v-if="countDown > 0" v-html="countDownText"></div>
                        <button :style="`background: ${this.$store.state.buttonColor} !important; color: ${this.$store.state.buttonTextColor} !important;`" type="button"
                            :disabled="isShowOkOtpButton"
                            class="btn bg-color-common btn-success col-lg-3 col-md-3 col-4"
                            data-bs-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
            <material-loading :active="loaderActive" />
        </div>
        <!-- Modal -->
        <ModalConfirm message="Do you want to sync your personal information from MINTY to current account ?"
            btnYes="Yes, sycn my profile" :sycn-profile="processSycnProfile" />

        <ModalCongratulation :message="congratulationName" btnClose="Close"
            btnGo="Go to My Reward" :voucherIconUrl="voucherIconUrl" :memberInfo="memberInfo"/>

            <div class="modal modal-bottom fade" id="bottom_modal_finished_reward_profile" tabindex="-1" role="dialog"
            aria-labelledby="bottom_modal_finished_reward_profile" style="z-index: 10000;">
            <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header  d-flex justify-content-end" style="border: 0; padding: 0.1rem 1rem;">
                <span aria-hidden="true" style="font-size: 25px; color: #222;" data-bs-dismiss="modal">&times;</span>
                </div>
                <div class="modal-body">
                <div class="row d-flex bd-highlight">
                    <div class="text-dark d-flex justify-content-center p-4">
                        Fill in all reward qualified fields
                    </div>
                    <div class="text-dark d-flex justify-content-center p-4">
                        <i v-if="reward && reward.isNameReward" class="fas fa-gift icon-reward" style="font-size: 50px;"></i>
                    </div>
                    <div class="text-dark d-flex justify-content-center p-4">
                        to get an amazing reward.
                    </div>
                </div>
                </div>
                <div class="text-center py-3">
                <div class="row d-flex justify-content-center">
                    <material-button class="btn btn-sm btn-secondary mb-3 col-lg-2 col-md-3 col-3 me-1 close-model" @click="handleUpdateProfile">
                    No, thanks
                    </material-button>
                    <material-button class="btn btn-sm mb-3 col-lg-2 col-md-3 col-6 bg-color-common ms-1"
                    :style="`background: ${this.$store.state.buttonColor} !important; color: black !important;`" data-bs-dismiss="modal">
                    Fill profile
                    </material-button>
                </div>
                </div>
            </div>
            </div>
            <button type="button" class="btn btn-primary btn-close-modal btn-sm" data-bs-dismiss="modal" style="display: none">Cancel</button>
            <button class="btn-finished-profile-reward-modal" style="display: none" data-bs-toggle="modal"
            data-bs-target="#bottom_modal_finished_reward_profile">Success</button>
        </div>
    </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import { mapMutations, mapActions } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import VOtpInput from "vue3-otp-input";
import MemberService from "../services/member.service";
import $ from "jquery";
import 'bootstrap/js/dist/modal'
import { ref } from "vue"
import ModalConfirm from "@/components/ModalConfirm.vue";
import ModalCongratulation from "@/components/ModalCongratulation.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import EventBus from "vue3-eventbus";

export default {
    name: "sign-in",
    components: {
        MaterialInput,
        MaterialButton,
        MaterialAlert,
        MaterialSnackbar,
        MaterialRadio,
        VOtpInput,
        ModalConfirm,
        ModalCongratulation,
        MaterialLoading,
        MaterialTextarea,
        Datepicker,
        MaterialCheckbox
    },
    setup() {
        const inputOTP = ref(null)

        const clearInputOTP = () => {
            inputOTP.value.clearInput()
        }

        const inputPassword = ref(null)

        const clearInputPassword = () => {
            inputPassword.value.clearInput()
        }

        return { inputOTP, clearInputOTP, inputPassword, clearInputPassword };
    },
    data() {
        return {
            form: {
                id: '',
                name: '',
                date_of_birth: '',
                gender: '',
                email: '',
                phone_number: '',
                address_1: '',
                address_2: '',
                postal_code: '',
                occupation: '',
                monthly_income_range: '1',
                marital_status: '',
                otp: '',
                discovery_channel: '',
                country_id: '',
                education: '',
                password: '',
                fields_another: [],
                isFinishedReward: 0, // 1 finished | 2 unfinished
            },
            snackbar: null,
            error: '',
            yourValue: {},
            memberInfo: {},
            timerEnabled: true,
            countDown: 45,
            countDownText: 'Change',
            timeout: '',
            timer_on: 0,
            required: null,
            countries: [],
            phone_number_old: '',
            isShowOkOtpButton: true,
            isDisablePhoneInput: true,
            isShowShareData: true,
            merchantCode: "",
            voucherIconUrl: '',
            congratulationName: "",
            dateOfBirthBefore: null,
            isDisableGetOtpBtn: false,
            loaderActive: false,
            displayFields: null,
            reward: null,
            tier_have_membership_fee_min: null,
            check_birthday_reward: false,
            isWarningCannotUpdateBirthday: false
        };
    },
    created() {
        this.getMemberInfo()
        this.getCountries()
    },
    mounted() {
        //load after payment done
        var session_id = this.$route.query.session_id;
        var price = this.$route.query.price;
        var tier_id = this.$route.query.tier_id;
        if(session_id) { // call back after pay success - stripe
            var dataForm = {
                session_id,
                amount: price,
                tier_id
            }
            MemberService.assignPaidMembershipRewardsToMember(dataForm).then(
                (response) => {
                    var dataResponse = response.data;
                    if (dataResponse.result.isSuccess) {
                        this.snackbar = 'success'
                        this.message = "Proceed successfully"
                        this.setTimeOutNotification()
                        EventBus.emit("reloadSideNav");
                        this.getMemberInfo();
                        this.$router.push(this.merchantCode + '/get-member-ship-success');
                    } else {
                        this.$router.push('/minty/profile');
                        // this.snackbar = 'danger'
                        // this.message = response.data.result.message
                        // this.setTimeOutNotification();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }

        const code = localStorage.getItem('MERCHANT_CODE', '');
        this.merchantCode = code ? `/${code}` : ''
    },
    computed: {
        styleObject() {
            return {
                '--label-color': this.$store.state.labelColor,
            };
        },
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),

        sendOTP() {
            var _this = this;
            if(_this.isDisablePhoneInput) {
                _this.isDisablePhoneInput = !_this.isDisablePhoneInput;
                if(!this.timer_on) {
                    _this.countDownText = "GET OTP";
                    _this.isDisableGetOtpBtn = true
                } else {
                    $('.open-modal').click()
                }
                return;
            }
            let data = {
                'phone_number': _this.form.phone_number,
                'is_profile': 1
            }
            _this.closeSnackbar()
            if (this.timer_on && $("#exampleModal").is(":hidden")) {
                $('.open-modal').click()
            }
            if (!this.timer_on) {
                _this.$store.dispatch("auth/sendOtp", data).then(
                    (data) => {
                        if (data != undefined && !data.result.isSuccess) {
                            _this.message = data.result.message
                            _this.snackbar = 'danger'
                        } else {
                            $('.open-modal').click()
                            _this.startCount()
                        }
                    },
                    (error) => {
                        console.log('ERROR' + error)
                    }
                );
            }
        },
        handleOnComplete: function (value) {
            this.form.otp = value
            this.isShowOkOtpButton = false;
        },
        handleOnChange: function (value) {
            if (value.length != 4) {
                this.form.otp = ''
                this.isShowOkOtpButton = true;
            }
        },
        handleOnCompletePassword: function (value) {
            this.form.password = value
        },
        handleOnChangePassword: function (value) {
            if (value.length != 4) {
                this.form.password = ''
            }
        },
        onChangePhoneNumber(event) {
            if (this.phone_number_old != event.target.value && event.target.value.length == 8) {
                this.isDisableGetOtpBtn = false
            } else {
                this.isDisableGetOtpBtn = true
            }
        },
        closeSnackbar() {
            this.snackbar = null;
        },
        timedCount() {
            this.isDisablePhoneInput = true;
            if (this.countDown > 0) {
                this.countDown -= 1
                this.countDownText = `${this.countDown} s`
                this.timeout = setTimeout(this.timedCount, 1000);
            } else {
                this.countDownText = 'Get OTP'
            }

        },
        startCount() {
            if (!this.timer_on) {
                this.timer_on = 1
                this.isDisablePhoneInput = true
                this.timedCount()
            }
        },
        stopCount() {
            this.countDownText = 'Get OTP'
            this.isDisablePhoneInput = false
            clearTimeout(this.timeout);
            this.countDown = 45
            this.timer_on = 0
        },
        getMemberInfo() {
            this.showLoader();
            MemberService.getProfile().then(
                (response) => {
                    this.memberInfo = response.data.data
                    console.log("this.memberInfo", this.memberInfo)
                    this.dateOfBirthBefore = this.memberInfo.date_of_birth ? this.memberInfo.date_of_birth : ''
                    this.form.email = this.memberInfo.email ? this.memberInfo.email : ''
                    this.form.phone_number = this.memberInfo.phone_number ? this.memberInfo.phone_number : ''
                    this.isShowShareData = this.memberInfo.is_populate_data;
                    this.required = this.memberInfo.fields.isRequired;
                    this.reward = this.memberInfo.fields.isReward;
                    this.displayFields = this.memberInfo.fields.isDisplay;
                    this.form.fields_another = this.memberInfo.fields_another;
                    this.tier_have_membership_fee_min = this.memberInfo.tier_have_membership_fee_min;
                    this.check_birthday_reward = this.memberInfo.check_birthday_reward
                    if (this.isShowShareData) {
                       this.setDataprofile();
                    } else {
                        $(".btn-close").click()
                        $(".btn-share-profile-modal").click()
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.hideLoader();
                    console.log(error);
                }
            );
        },
        setDataprofile() {
            this.form.id = this.memberInfo.id
            this.form.name = this.memberInfo.name ? this.memberInfo.name : ''
            this.form.date_of_birth = this.memberInfo.date_of_birth ? this.memberInfo.date_of_birth : ''
            this.form.gender = this.memberInfo.gender ? String(this.memberInfo.gender) : ''
            this.form.address_1 = this.memberInfo.address_1 ? this.memberInfo.address_1 : ''
            this.form.address_2 = this.memberInfo.address_2 ? this.memberInfo.address_2 : ''
            this.form.postal_code = this.memberInfo.postal_code ? this.memberInfo.postal_code : ''
            this.form.occupation = this.memberInfo.occupation ? this.memberInfo.occupation : ''
            this.form.monthly_income_range = this.memberInfo.monthly_income_range ? this.memberInfo.monthly_income_range : '1'
            this.form.marital_status = this.memberInfo.marital_status ? this.memberInfo.marital_status : ''
            this.form.discovery_channel = this.memberInfo.discovery_channel ? this.memberInfo.discovery_channel : ''
            this.form.country_id = this.memberInfo.country_id ? this.memberInfo.country_id : ''
            this.form.education = this.memberInfo.education ? this.memberInfo.education : ''
            this.phone_number_old = this.memberInfo.phone_number ? this.memberInfo.phone_number : ''
            this.form.password = ''
        },
        getCountries() {
            MemberService.getCountries().then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.countries = response.data.data
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        updateProfile() {
            this.closeSnackbar()
            if (this.processValidate()) {
                if(this.form.isFinishedReward == 2) {
                    $(".btn-finished-profile-reward-modal").click();
                } else {
                    this.handleUpdateProfile();
                }
            }
        },
        handleUpdateProfile() {
            this.form.isFinishedReward = 1;
            MemberService.updateProfile(this.form).then(
                (response) => {
                    var dataResponse = response.data;
                    if (dataResponse.result.isSuccess) {
                        this.snackbar = 'success'
                        this.message = "Update successful"
                        this.setTimeOutNotification()
                        this.getMemberInfo()
                        this.resetRequired();
                        this.clearInputOTP()
                        if(this.displayFields && this.displayFields.isPasscodeDisplay) {
                            this.clearInputPassword()
                        }
                        this.stopCount()
                        this.countDownText = 'Change';
                        this.isDisablePhoneInput =  !this.isDisablePhoneInput;
                        this.isDisableGetOtpBtn = false
                        console.log("dataResponse.data", dataResponse.data);
                        // if(dataResponse.data.is_get_reward && dataResponse.data.is_get_reward == true) {
                        //     // var name = dataResponse.data.name ?? "you"
                        //     // this.voucherIconUrl = dataResponse.data.voucher_icon_url
                        //     // this.congratulationName = "Congratulation,<br/> " + name + "!"
                        //     // $(".btn-show-modal-congratulation").click();
                        // } else {
                            const isCheckMerchantConnectedStripe = localStorage.getItem("IS_CHECK_MERCHANT_CONNECTED_STRIPE", 'false');
                            var count_paid_membership_reward_by_merchant = this.memberInfo.count_paid_membership_reward_by_merchant;
                            if(!dataResponse.data.is_paid_member_ship && isCheckMerchantConnectedStripe === 'true' && count_paid_membership_reward_by_merchant > 0 && this.tier_have_membership_fee_min) {
                                this.$router.push(this.merchantCode + '/get-member-ship');
                            }
                        // }
                        // $(".btn-show-modal-congratulation").click();
                        $(".btn-close-modal").click();
                    } else {
                        this.snackbar = 'danger'
                        this.message = response.data.result.message
                        this.setTimeOutNotification()
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        setTimeOutNotification() {
            setTimeout(() => { this.snackbar = null; }, 3000);
        },
        resetRequired() {
            this.required.isNameRequire = false;
            this.required.isDateRequire = false;
            this.required.isGenderRequire = false;
            this.required.isEmailRequire = false;
            this.required.isHouseAddressRequire = false;
            this.required.isPostCodeRequire = false;
            this.required.isOccupationRequire = false;
            this.required.isDiscoveryRequire = false;
            this.required.isMonthlyIncomeRangeRequire = false;
        },
        showMessage(message) {
            this.snackbar = 'danger'
            this.message = message
            this.setTimeOutNotification()
        },
        processValidate() {
            // this.resetRequired();
            var isValid = true;
            if (this.displayFields.isNameDisplay && this.required.isNameRequire && this.form.name == '') {
                // this.required.isNameRequire = true;
                this.showMessage("Name is required.");
                isValid = false;
            }
            if (this.displayFields.isNameDisplay && this.reward.isNameReward && this.form.name == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isDateDisplay && this.required.isDateRequire && this.form.date_of_birth == '') {
                // this.required.isDateRequire = true;
                this.showMessage("Date of Birth is required.");
                isValid = false;
            }
            if (this.displayFields.isDateDisplay && this.reward.isDateReward && this.form.date_of_birth == '') {
                this.form.isFinishedReward = 2;
            }  else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isGenderDisplay && this.required.isGenderRequire && this.form.gender == '') {
                // this.required.isGenderRequire = true;
                this.showMessage("Gender is required.");
                isValid = false;
            }
            if (this.displayFields.isGenderDisplay && this.reward.isGenderReward && this.form.gender == '') {
                this.form.isFinishedReward = 2;
            }  else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isEmailDisplay && this.required.isEmailRequire && this.form.email == '') {
                // this.required.isEmailRequire = true;
                this.showMessage("Email Address is required.");
                isValid = false;
            }
            if (this.displayFields.isEmailDisplay && this.reward.isEmailReward && this.form.email == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            if (this.form.phone_number == '') {
                // this.required.isPhoneRequire = true;
                this.showMessage("Mobile Number is required.");
                isValid = false;
            }
            
            if (this.displayFields.isHouseAddressDisplay && this.required.isHouseAddressRequire &&  this.form.address_1 == '') {
                this.showMessage("Address is required.");
                // this.required.isPostCodeRequire = true;
                isValid = false;
            }
            if (this.displayFields.isHouseAddressDisplay && this.reward.isHouseAddressReward &&  this.form.address_1 == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isPostCodeDisplay && this.required.isPostCodeRequire &&  this.form.postal_code == '') {
                this.showMessage("Postal Code is required.");
                // this.required.isPostCodeRequire = true;
                isValid = false;
            }
            if (this.displayFields.isPostCodeDisplay && this.reward.isPostCodeReward &&  this.form.postal_code == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isDiscoveryDisplay && this.required.isDiscoveryRequire && this.form.discovery_channel == '') {
                this.showMessage("Discovery Channel is required.");
                // this.required.isDiscoveryRequire = true;
                isValid = false;
            }
            if (this.displayFields.isDiscoveryDisplay && this.reward.isDiscoveryReward && this.form.discovery_channel == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isOccupationDisplay && this.required.isOccupationRequire && this.form.occupation == '') {
                this.showMessage("Occupation is required.");
                // this.required.isDiscoveryRequire = true;
                isValid = false;
            }
            if (this.displayFields.isOccupationDisplay && this.reward.isOccupationReward && this.form.occupation == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isMonthlyIncomeRangeDisplay && this.required.isMonthlyIncomeRangeRequire && this.form.monthly_income_range == '') {
                this.showMessage("Monthly Income Range is required.");
                // this.required.isDiscoveryRequire = true;
                isValid = false;
            }
            if (this.displayFields.isMonthlyIncomeRangeDisplay && this.reward.isMonthlyIncomeRangeReward && this.form.monthly_income_range == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isAddress2Display && this.required.isAddress2Require && this.form.address_2 == '') {
                this.showMessage("Address 2 is required.");
                isValid = false;
            }
            if (this.displayFields.isAddress2Display && this.reward.isAddress2Reward && this.form.address_2 == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isMaritalStatusDisplay && this.required.isMaritalStatusRequire && this.form.marital_status == '') {
                this.showMessage("Marital Status is required.");
                isValid = false;
            }
            if (this.displayFields.isMaritalStatusDisplay && this.reward.isMaritalStatusReward && this.form.marital_status == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isCountryOfResidentDisplay && this.required.isCountryOfResidentRequire && this.form.country_id == '') {
                this.showMessage("Country Of Resident is required.");
                isValid = false;
            }
            if (this.displayFields.isCountryOfResidentDisplay && this.reward.isCountryOfResidentReward && this.form.country_id == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isEducationDisplay && this.required.isEducationRequire && this.form.education == '') {
                this.showMessage("Education is required.");
                isValid = false;
            }
            if (this.displayFields.isEducationDisplay && this.reward.isEducationReward && this.form.education == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            if (this.displayFields.isPasscodeDisplay && this.required.isPasscodeRequire && this.form.password == '') {
                this.showMessage("Passcode is required.");
                isValid = false;
            }
            if (this.displayFields.isPasscodeDisplay && this.reward.isPasscodeReward && this.form.password == '') {
                this.form.isFinishedReward = 2;
            } else {
                this.form.isFinishedReward = 1;
            }

            // check field another
            console.log("this.form.fields_another", this.form.fields_another)
            this.form.fields_another.map(element => {
                if (element.is_display === 1 && element.is_required === 1 && (element.field_value == null || element.field_value == false || element.field_value === "" || (element.field_value && element.field_value.length == 0))) {
                    this.showMessage(element.field_name + " is required.");
                    isValid = false;
                }
                
                if (element.is_display === 1 && element.is_reward === 1 && (element.field_value == null || element.field_value == false || element.field_value === "" || (element.field_value && element.field_value.length == 0))) {
                    this.form.isFinishedReward = 2;
                }
            });

            return isValid;
        },
        processSycnProfile() {
            this.setDataprofile()
            $(".close-model").click()
        },
        goToMyReward() {
            $(".btn-show-modal-congratulation").click()
            this.$router.push(this.merchantCode + '/my-rewards');
        },
        showLoader() {
            this.loaderActive = true;
        },
  
        hideLoader() {
            this.loaderActive = false;
        },
        addMultipleChoice(itemRoot, itemChild) {
            const objectToUpdate = this.form.fields_another.find(obj => obj.id === itemRoot.id && obj.is_multiple_choice === 1);
            if (objectToUpdate) {
                if (!Array.isArray(objectToUpdate.field_value)) {
                    objectToUpdate.field_value = [];
                }
                const index = objectToUpdate.field_value.indexOf(itemChild.id);
                if (index !== -1) {
                    objectToUpdate.field_value.splice(index, 1);
                } else {
                    objectToUpdate.field_value.push(itemChild.id);
                }
            }
        },
        uploadFile(item) {
            $("#"+item.field_name_convert).click();
        },
        clearImage(item) {
            const objectToUpdate = this.form.fields_another.find(obj => obj.id === item.id && obj.is_file_upload === 1);
            objectToUpdate.field_value = "";
            if(objectToUpdate.field_value_preview) {
                objectToUpdate.field_value_preview = "";
            }
            objectToUpdate.isDelete = 2;
        },
        handleFileUpload(event, item) {
            let file = event.target.files[0];
            if (file != null || file != undefined) {
                if (
                    !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
                        file.type
                    )
                ) {
                    this.showMessage("File must be an image.");
                } else {
                    const objectToUpdate = this.form.fields_another.find(obj => obj.id === item.id && obj.is_file_upload === 1);
                    objectToUpdate.field_value = file;
                    objectToUpdate.field_value_preview = URL.createObjectURL(file);
                    objectToUpdate.isDelete = 1;
                }
            } else {
                this.showMessage("File must be an image.");
            }
        },
        warningCannotUpdateBirthday() {
            this.isWarningCannotUpdateBirthday = true;
            setTimeout(() => { this.isWarningCannotUpdateBirthday = false; }, 3000);
        }
    },
    watch: {
        countDown(newValue) {
            if (newValue == 0) {
                this.countDownText = 'Change';
                this.form.otp = ''
                this.stopCount()
                this.clearInputOTP()
                $('.btn-close').click()
            }
        }
    },
};
</script>
<style>
.boder-b {
    border-bottom: 2px solid var(--label-color);
}

.pl-0 {
    padding-left: 0px !important;
}

.monthly-group .form-check {
    padding-left: 12px !important;
}

.btn-share-profile {
    color: #007BFF !important;
    text-decoration: underline !important;
}

.title-share-profile {
    font-size: 13px;
    font-weight: 600;
}

.icon-reward {
    color: #BE9104 !important;font-size: 20px;margin-left: 10px !important;
}
</style>