<template>
    <div class="page-header align-items-start min-vh-screen">
      <span class="mask bg-white"></span>
      <div class="container p-0">
        <div class="row">
          <div class="col-lg-12 col-md-8 col-12 mx-auto">
            <div class="card-body" style="padding: 0rem 0rem">
              <div>
                <div class="color-common pb-2 font-weight-bolder px-2 pt-2">
                  <h3
                    class="ps-3"
                    :style="`color: ${this.$store.state.labelColor} !important`"
                  >
                    MY PROFILE
                  </h3>
                </div>
                <div class="row d-flex justify-content-center">
                  <ul class="reward-tab" :style="styleObject">
                    <li
                      class="nav-item active"
                      id="nav_item_1"
                      @click="changeTab(1)"
                      :style="`color: ${this.$store.state.labelColor} !important`"
                    >
                      <strong>Pesonal Info</strong>
                    </li>
                    <li
                      class="nav-item"
                      id="nav_item_2"
                      @click="changeTab(2)"
                      :style="`color: ${this.$store.state.labelColor} !important`"
                    >
                      <strong>Preference</strong>
                    </li>
                  </ul>
                </div>
                <div>
                  <div class="wrap-card ps-1 pe-1">
                      <div v-if="typeChecked == 1">
                        <profile />
                      </div>
                      <div v-if="typeChecked == 2">
                        <div class="col-lg-7 col-md-12 col-12 mx-auto mx-2 my-2">
                          <div class="card-custom-new ps-3 pe-3" style="height: 100%;">
                            <div class="font-weight-bolder text-dark title-share-profile">
                                Share your preference to get better service.
                            </div>
                            <div  v-for="(value, key) in memberPreferenceData" :key="key" class="row mt-4">
                                <div class="col-5" style="align-self: center;">
                                    {{ key+1 +" : "+value.name }}
                                </div>
                                <div class="col-7">
                                  <Multiselect @change="changeMemberPreference($event, value.id)" v-model="value.value_selected" :options="value.value_select" placeholder="Value" label="label" :canClear="false" />
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2">
          <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
          />
        </div>
      </div>
      <material-loading :active="loaderActive" />
      
    </div>
  </template>
  
  <script>
  import { mapMutations, mapActions, mapState } from "vuex";
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import "bootstrap/js/dist/modal";
  import $ from "jquery";
  import Profile from '../views/Profile.vue'
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import MemberService from "../services/member.service";
  import MemberPreferenceService from "../services/memberPreference.service";
  
  export default {
    name: "my-profile",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      Profile,
      Multiselect
    },
    data() {
      return {
        snackbar: null,
        loaderActive: false,
        merchantCode: "",
        typeChecked: 1,
        memberId: 0
      };
    },
    created() {
      this.getMemberInfo();
      this.changeTab(1);
    },
    computed: {
      styleObject() {
        return {
          "--label-color": this.$store.state.labelColor,
        };
      },
    },
    mounted() {
      const code = localStorage.getItem("MERCHANT_CODE", "");
      this.merchantCode = code ? `/${code}` : "";
    },
    methods: {
      ...mapMutations([
        "toggleEveryDisplay",
        "toggleHideConfig",
        "navbarMinimize",
      ]),
      ...mapActions(["setHeaderColor", "setLabelColor", "setButtonColor"]),
      ...mapState(["isPinned"]),
  
      closeSnackbar() {
        this.snackbar = null;
      },
  
      setTimeOutNotification() {
        setTimeout(() => {
          this.snackbar = null;
        }, 3000);
      },
  
      changeTab(type) {
        $(".nav-item").removeClass("active");
        $("#nav_item_" + type).addClass("active");
        this.typeChecked = type;
      },
  
      showLoader() {
        this.loaderActive = true;
      },
      hideLoader() {
        this.loaderActive = false;
      },
      getMemberInfo() {
          MemberService.getProfile().then(
              (response) => {
                  this.memberId = response.data.data.id;
                  this.getMembePreference(response.data.data.id);
              },
              (error) => {
                  console.log(error);
              }
          );
      },
      getMembePreference(memberId) {
        this.isLoadData = false;
        this.showLoader();
        MemberPreferenceService.getListDropdown(memberId).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.memberPreferenceData = response.data.data;
              this.isLoadData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      },
      changeMemberPreference(value_select_id, id) {
        if(value_select_id && id) {
            var memberId = this.memberId;
            this.isLoadData = false;
            this.showLoader();
            MemberPreferenceService.changeMemberPreference({memberId, minty_merchant_preference_value_id: value_select_id, minty_merchant_preference_id: id}).then(
            (response) => {
                if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Update Member Preference Success";
                this.setTimeOutNotification();
                this.getMembePreference(memberId);
                this.isLoadData = true;
                } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
                }
                this.hideLoader();
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
            }
            );
        }
      },
    },
  };
  </script>
  <style scoped>
  .page-header .container {
    z-index: unset !important;
  }

  .page-header .mask {
    z-index: -1 !important;
  }

  .btn-exchange {
    color: rgb(248, 37, 37);
    font-weight: 700;
    border: 1px solid rgb(248, 37, 37);
  }
  
  .reward-tab {
    margin: 0px !important;
    padding: 0px;
    width: 90% !important;
  }
  
  .reward-tab li {
    list-style: none;
    float: left;
    width: 50%;
    text-align: center;
    padding: 5px;
    cursor: pointer;
  }
  
  .reward-tab li.active {
    border-bottom: 2px solid var(--label-color);
    color: var(--label-color);
  }

  @media only screen and (max-width: 600px) {
    .wrap-card .card-item:last-child {
      margin-bottom: 80px !important;
    }
  }
  </style>